<template>
  <v-container>
    <v-toolbar elevation="1">
      <v-toolbar-title class="text-h5 font-weight-regular title">Request Scraper</v-toolbar-title>
      <v-spacer />
      <v-btn outlined @click="() => this.fetchRequestImages()">
        <v-icon class="mr-1">mdi-reload</v-icon>
        <div class="reload-text">Reload</div>
      </v-btn>
    </v-toolbar>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :search="filterText"
          :headers="requestImageHeaders"
          :items="requestImages"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :server-items-length="totalItems"
          disable-sort
          item-key="id"
          class="elevation-2"
          id="match-table"
          :footer-props="footerProps"
          style="cursor: pointer"
          @click:row="(item) => $router.push(requestImageUrl(item))"
        >
          <template v-slot:top>
            <div class="pa-2 lighten-2 d-flex align-center">
              <v-text-field
                :value="query"
                solo
                clearable
                @click:clear="searchPressed"
                hide-details
                placeholder="Search"
                prepend-inner-icon="mdi-magnify"
                @keypress.enter="searchPressed"
                @focusout="searchPressed"
              />
            </div>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.requestUserId`]="{ item }">
            <v-btn outlined :to="{ name: 'user.view', params: { id: item.requestUserId } }">
              {{ item.requestUserName }}
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small outlined :to="requestImageUrl(item)"> More </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FACE_REQUEST_IMAGES } from "@/constants/api";

export default {
  name: "RequestImageIndex",
  metaInfo: { title: "Request Images" },
  components: {},
  data() {
    return {
      loading: false,
      filterText: null,
      requestImageHeaders: [
        { text: "Requested At", value: "createdAt", sortable: true },
        { text: "Request By", value: "requestUserId", sortable: true },
        { text: "Type", value: "type", sortable: true },
        { text: "Content Type", value: "mimeType", sortable: true },
        { text: "", value: "actions", sortable: false },
      ],
      requestImages: [],
      roles: {},
      selectedRequestImage: null,
      action: null,
      page: 1,
      query: null,
      itemsPerPage: 15,
      totalItems: null,
      optionsLength: 6,
      footerProps: { "items-per-page-options": [15, 30, 60, 100] },
    };
  },
  mounted() {
    this.query = this.$route.query.query || null;
    this.page = Number(this.$route.query.page) || 1;
    this.itemsPerPage = Number(this.$route.query.limit) || 15;
    this.fetchRequestImages();
  },
  computed: {
    pagination() {
      return { page: this.page - 1, limit: this.itemsPerPage, query: this.query };
    },
  },
  watch: {
    pagination() {
      if (
        Number(this.$route.query.page) !== this.page ||
        Number(this.$route.query.limit) !== this.itemsPerPage
      ) {
        this.$router.push({
          query: { page: this.page, limit: this.itemsPerPage, query: this.query },
        });
      }
    },
    $route({ name }) {
      if (name === "request-image.index") {
        this.query = this.$route.query.query || null;
        this.page = Number(this.$route.query.page) || 1;
        this.itemsPerPage = Number(this.$route.query.limit) || 15;
        this.fetchRequestImages();
      }
    },
  },
  methods: {
    fetchRequestImages() {
      this.loading = true;
      return this.$axios
        .get(FACE_REQUEST_IMAGES, {
          params: this.pagination,
        })
        .then((response) => {
          if (response.data.code === 0) {
            this.totalItems = response.data.data.totalItems;
            this.requestImages = response.data.data.items;
          } else {
            throw new Error(response.data.message);
          }
        })
        .catch((err) => {
          this.$iziToast.showError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    requestImageUrl(requestImage) {
      return {
        name: "request-image.single",
        params: {
          md5sum: requestImage.md5sum,
        },
      };
    },
    searchPressed(data) {
      if (data && data.target) {
        this.$router.push({
          query: { page: this.page, limit: this.itemsPerPage, query: data.target.value },
        });
      }
    },
  },
};
</script>
